<template>
  <v-container class="grey lighten-5 mb-6" fluid>
    <v-row>
      <v-col>
        <v-card class="mx-auto form-card-padding">
          <v-toolbar flat>
            <v-toolbar-title class="grey--text">
              {{ $t('country.add.title') }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider></v-divider>
          <v-text-field
            v-model="countryData.iso"
            :rules="validation.iso"
            :counter="2"
            :label="$t('country.add.iso')"
            required
          ></v-text-field>

          <v-text-field
            v-model="countryData.name"
            :rules="validation.name"
            :counter="255"
            :label="$t('country.add.name')"
            required
          ></v-text-field>

          <v-text-field
            v-model="countryData.nicename"
            :rules="validation.nicename"
            :counter="255"
            :label="$t('country.add.nicename')"
            required
          ></v-text-field>

          <v-text-field
            v-model="countryData.iso3"
            :rules="validation.iso3"
            :counter="3"
            :label="$t('country.add.iso3')"
            required
          ></v-text-field>

          <v-text-field
            v-model="countryData.numcode"
            :rules="validation.nicename"
            :counter="6"
            :label="$t('country.add.numCode')"
            required
          ></v-text-field>

          <v-text-field
            v-model="countryData.phonecode"
            :rules="validation.phonecode"
            :counter="5"
            :label="$t('country.add.phoneCode')"
            required
          ></v-text-field>

          <v-btn
            color="success"
            class="mr-4 align-self-lg-end"
            @click="createCountry()"
            >{{ $t('country.add.controlls.update') }}</v-btn>
        </v-card>
      </v-col>
    </v-row>
    <error-handler :error="error"></error-handler>
  </v-container>
</template>

<script>
export default {
  name: "AddCountry",

  data() {
    return {
      error: null,
      validation: {
        iso: [
          (v) => !!v || "ISO is required",
          (v) =>
            (v && v.length == 2) || "ISO must be 2 characters",
        ],
        name: [
          (v) => !!v || "Name is required",
          (v) =>
            (v && v.length <= 255) ||
            "Name must be less than 255 characters",
        ],
        nicename: [
          (v) => !!v || "Name is required",
          (v) =>
            (v && v.length <= 255) ||
            "Name must be less than 255 characters",
        ],
        iso3: [
          (v) => !!v || "ISO3 is required",
          (v) =>
            (v && v.length == 3) ||
            "ISO3 must be 3 characters",
        ],
        numcode: [
          (v) => !!v || "Numcode is required",
          (v) =>
            (v && v.length <= 6) ||
            "Numcode must be less r equal to 6 characters",
        ],
        phonecode: [
          (v) => !!v || "Phonecode is required",
          (v) =>
            (v && v.length <= 5) ||
            "Phonecode must less or equal to 5 characters",
        ],
      },
      countryData: {
        iso: "",
        name: "",
        nicename: "",
        iso3: "",
        numcode: "",
        phonecode: "",
      },
    };
  },
  methods: {
    createCountry() {
    
      this.$axios({
        method: "post",
        url: this.$globals.api.country.create,
        data: this.countryData,
      })
        .then(() => {
          this.$router.push({ name: "country/list" });
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
  }
};
</script>
